import * as React from 'react'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import Link from 'gatsby-link'

// COMPONENTS
import { Button } from 'components/styled/button'

// ASSETS
import ArrowIcon from 'svgs/arrow_top_right.svg'
import MenuIcon from 'svgs/newDesigns/menu-icon.svg'
import CloseIcon from 'svgs/close-v2.svg'
import Logo from 'svgs/logo.svg'
import { SCREEN } from 'styles/screens'
import ProfilePic from 'images/profile_placeholder.png'
import ArrowBottomIcon from 'svgs/arrow_bottom.svg'
import { getTranslation } from '../../data/dictionary'
import fetch from 'node-fetch'
import Api from '../../helpers/api'

const Container = styled.div`
  .logout {
    cursor: pointer;
  }

  .link-button {
    margin-bottom: 15px;
    border: 1.2px solid #1a1a1a;
    padding: 8px 25px;
    display: flex;
    text-decoration: none;
    font-weight: bold;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    border-radius: 20px;
    color: #221f1f;
  }

  .inner-close {
    margin-left: auto;
  }

  #mobile-menu-button {
    position: relative;
    height: 50px;
    width: 50px;
  }

  .menu-is-opened {
    .menu-icon {
      transform-origin: center;
      transform: scale(0.2);
      opacity: 0;
      visisbility: hidden;
    }

    .close-icon {
      transform-origin: center;
      transform: translateX(-50%) scale(1);
      opacity: 1;
      visisbility: visible;
    }
  }

  .close-icon {
    transform-origin: center;
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%) scale(0.2);
    opacity: 0;
    visisbility: hidden;
    transition: all 0.4s ease-in-out;
  }

  .menu-icon {
    transform-origin: center;
    position: absolute;
    top: 18px;
    left: 50%;
    transform: translateX(-50%);

    transition: all 0.4s ease-in-out;
  }

  #mobile-menu {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    padding: 25px;
    width: 250px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    .link {
      margin-bottom: 15px;
      color: #000;
      text-decoration: none;
      font-weight: 500;
    }

    button {
      margin-top: 15px;
    }
  }

  #mobile-menu.show {
    display: flex;
    flex-direction: column;
  }

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(8px);
  z-index: 102;
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 0 40px;
  height: var(--header);
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
    max-width: 100vw;
  }
`

const FixedContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1520px;
  position: relative;
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
  }
`

const StyledLogo = styled(Logo)`
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    width: 72px;
  }
`

const LinksContainer = styled.div`
  display: flex;
  align-items: center;

  > a,
  button {
    user-select: none;
    position: relative;
    text-decoration: none;
    color: var(--primary);
    margin: 0 calc(var(--spacing) * 4);
    font-size: 20px;
    font-weight: 500;

    &.link {
      &:before {
        content: '';
        position: absolute;
        left: -15px;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
        border-radius: 50%;
        width: 67px;
        height: 51px;
        transition: all 300ms ease;
      }

      &.active {
        &:before {
          background-color: var(--secondary);
        }
      }

      &:hover {
        &:before {
          background-color: var(--secondary);
        }
      }
    }
  }

  .disabled {
    pointer-events: none;
  }

  button:last-child {
    margin-right: 0;
  }

  button {
    font-weight: 500;

    > svg {
      margin-left: calc(var(--spacing) * 3.5);
    }
  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    > a {
      margin: 0 8px;
      font-size: 16px;
    }

    button {
      display: none;
    }
  }
`

const UserInfo = styled.div`
  margin: 0 12px;
  display: flex;
  align-items: center;
  cursor: pointer;

  > p {
    font-size: 0.8rem;
    font-weight: 500;
    margin: 0;
  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    margin: 0 4px;
    > p {
      display: none;
    }
  }
`
const ProfilePicContainer = styled.div`
  //position: relative;
  margin: 0 10px;
  border-radius: 50%;
  border: 1px solid black;
  height: 44px;
  width: 44px;

  > img {
    height: 100%;
    width: 100%;
  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    margin: 0 6px;
    height: 22px;
    width: 22px;
  }
`

const Dropdown = styled.div`
  background-color: var(--white);
  box-shadow: 0 16px 60px rgba(88, 80, 187, 0.12);
  padding: 8px 16px;
  border-radius: calc(var(--border-radius) / 2);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 220px;
  min-height: 90px;
  position: absolute;
  top: 80%;
  right: 0;

  > a {
    margin: 0;
    padding-bottom: 5px;
    padding-top: 5px;
    font-weight: 600;
    user-select: none;
    text-decoration: none;
    color: var(--primary);
  }

  > a:hover {
    text-decoration: underline;
    underline-color: var(--primary);
  }

  > p {
    cursor: pointer;
    margin: 2px 0;
    font-weight: 500;
    // color: #ff9595;
  }

  > p:hover {
    color: red;
  }

  hr {
    box-sizing: content-box;
    height: 0;
    overflow: hidden;
    width: 100%;
    box-shadow: none;
    outline: none;
    color: 1px solid black;
    border-color: #000;
  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    bottom: -100px;
    width: 150px;
    min-height: 50px;
  }
`

function useOutsideClick(ref, handler) {
  React.useEffect(() => {
    if (typeof document !== undefined) {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          handler()
        }
      }

      document.addEventListener('mousedown', handleClickOutside)
      document.addEventListener('touchend', handleClickOutside)

      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
        document.removeEventListener('touchend', handleClickOutside)
      }
    }
  }, [ref])
}

const openMenu = id => {
  // console.log(id)
  let menu = document.getElementById(id)
  let mobileMenuButton = document.getElementById('mobile-menu-button')

  menu.classList.add('show')
  if (mobileMenuButton) {
    mobileMenuButton.classList.add('menu-is-opened')
  }
}

const closeMenu = id => {
  // console.log(id)
  let menu = document.getElementById(id)
  let mobileMenuButton = document.getElementById('mobile-menu-button')

  menu.classList.remove('show')
  if (mobileMenuButton) {
    mobileMenuButton.classList.remove('menu-is-opened')
  }
}

const Header = ({ handleModal, signedIn, signOut, user }) => {

  const [baseDomain, setBaseDomain] = useState('')

  const [dropdown, showDropdown] = React.useState(false)
  const wrapperRef = React.useRef(null)
  const wrapperRef2 = React.useRef(null)
  useOutsideClick(wrapperRef, () => showDropdown(false))
  useOutsideClick(wrapperRef2, () => closeMenu('mobile-menu'))
  const [registered, setRegistered] = useState(false)

  useEffect(() => {
    if (window.localStorage.getItem('registered')) {
      setRegistered(true)
    }
  }, [])


  useEffect(() => {
    const domainsToCheck = ['buildup.am', 'localhost']
    if (!domainsToCheck.includes(window.location.hostname)) {
      setBaseDomain('https://' + domainsToCheck[0])
    }
  }, [])

  const [programs, setPrograms] = useState([])

  useEffect(async () => {
    fetch(`https://backend.buildup.am/programs`)
      .then(response => response.json())
      .then(response => {
        setPrograms(response.filter(program => program.enabled))
      })
  }, [])
  console.log({ baseDomain })

  const [student, setStudent] = useState(null)

  useEffect(() => {
    if (!user) {
      return
    }
    Api.getInstance()
      .getStudents()
      .then(response => {
        setStudent(
          response.find(s => {
            return s.email === user.email
          }),
        )
      })
      .catch(e => {
      })
      .finally(() => {
      })
  }, [user])

  const [hideHeaderOnUrls, setHideHeaderOnUrls] = useState(true)

  useEffect(() => {
    const urls = ['/courses', '/profile', '/challenge-list']
    let flag = false
    for (let i = 0; i < urls.length; i++) {
      if (window.location.pathname.startsWith(urls[i])) {
        flag = true
        break
      }
    }
    setHideHeaderOnUrls(flag)
  }, [])


  return (
    <Container>
      <FixedContainer>
        <Link to={`${baseDomain}/`}>
          <StyledLogo />
        </Link>

        <div className='d-desktop-none'>
          {signedIn ? (
            <>
              <UserInfo onClick={() => openMenu('mobile-menu')}>
                <p>
                  {user.firstName} {user.lastName}
                </p>
                <ProfilePicContainer>
                  {/*<img src={`${config.CDN_BASE_URL}/${user.image || DEFAULT_PROFILE_IMAGE}`} alt='profile image' />*/}
                  <img src={ProfilePic} alt='profile avatar' />
                </ProfilePicContainer>
                <ArrowBottomIcon />
              </UserInfo>
            </>
          ) : (
            <>
              <div id='mobile-menu-button'>
                <div className='menu-icon'>
                  <MenuIcon onClick={() => openMenu('mobile-menu')} />
                </div>
                <div className='close-icon'>
                  <CloseIcon onClick={() => closeMenu('mobile-menu')} />
                </div>
              </div>
            </>
          )}

          <div id='mobile-menu' ref={wrapperRef2}>
            {signedIn ? (
              <div className='d-flex flex-column'>
                <div className='inner-close'>
                  <CloseIcon onClick={() => closeMenu('mobile-menu')} />
                </div>

                <Link
                  className='link-button'
                  activeClassName='active'
                  to='/profile'
                >
                  Profile Settings
                  <ArrowIcon />
                </Link>
                <Link
                  className='link-button'
                  activeClassName='active'
                  to='/challenge-list/?onlyMine=true'
                >
                  My Challenges
                  <ArrowIcon />
                </Link>

                {!hideHeaderOnUrls &&
                  <>
                    <Link className='link' activeClassName='active' to={`${baseDomain}/community`}>
                      {getTranslation('Community', 'Community')}
                    </Link>
                    <Link className='link' activeClassName='active' to={`${baseDomain}/program`}>
                      {getTranslation('Programs', 'Programs')}
                    </Link>
                    <Link className='link' activeClassName='active' to={`${baseDomain}/about`}>
                      {getTranslation('About', 'About')}
                    </Link>
                    <Link className='link' activeClassName='active' to={`${baseDomain}/portfolio`}>
                      {getTranslation('Portfolio', 'Portfolio')}
                    </Link>
                    <Link className='link' activeClassName='active' to='/blog'>
                      {getTranslation('Blog', 'Blog')}
                    </Link>
                  </>
                }
                <p className='logout' onClick={() => signOut()}>
                  Log Out
                </p>
              </div>
            ) : (
              <>

                {/* <Link className="link" activeClassName="active" to="/community"> */}
                {/* {getTranslation('Community', 'Community')} */}
                {/* </Link> */}
                {!hideHeaderOnUrls && <>
                  <Link className='link' activeClassName='active' to={`${baseDomain}/program`}>
                    {getTranslation('Programs', 'Programs')}
                  </Link>
                  <Link className='link' activeClassName='active' to={`${baseDomain}/about`}>
                    {getTranslation('About', 'About')}
                  </Link>
                  <Link className='link' activeClassName='active' to={`${baseDomain}/portfolio`}>
                    {getTranslation('Portfolio', 'Portfolio')}
                  </Link>
                  <Link className='link' activeClassName='active' to={`${baseDomain}/blog`}>
                    {getTranslation('Blog', 'Blog')}
                  </Link>
                </>
                }
                <Button
                  className='m-0'
                  onClick={() => {
                    window.location.href = `${baseDomain}/program`
                  }}
                >
                  {getTranslation('Apply', 'Apply')}
                  &nbsp;
                  <ArrowIcon />
                </Button>

                <Button onClick={() => handleModal('login')} secondary>
                  {getTranslation('Login', '', true)} &nbsp;
                  <ArrowIcon />
                </Button>
              </>
            )}
          </div>
        </div>


        <LinksContainer className='d-mobile-none'>
          {!hideHeaderOnUrls && <>
            <Link className='link' activeClassName='active' to={`${baseDomain}/community`}>
              {getTranslation('Community', 'Community')}
            </Link>
            <Link className='link' activeClassName='active' to={`${baseDomain}/program`}>
              {getTranslation('Programs', 'Programs')}
            </Link>
            <Link className='link' activeClassName='active' to={`${baseDomain}/about`}>
              {getTranslation('About', 'About')}
            </Link>
            <Link className='link' activeClassName='active' to={`${baseDomain}/portfolio`}>
              {getTranslation('Portfolio', 'Portfolio')}
            </Link>
            <Link className='link' activeClassName='active' to={`${baseDomain}/blog`}>
              {getTranslation('Blog', 'Blog')}
            </Link>
          </>
          }

          {signedIn ? (
            <UserInfo
              className='d-mobile-none'
              onClick={() => showDropdown(true)}
            >
              <p>
                {user.firstName} {user.lastName}
              </p>
              <ProfilePicContainer>
                {/*<img src={`${config.CDN_BASE_URL}/${user.image || DEFAULT_PROFILE_IMAGE}`} alt='profile image' />*/}
                <img src={ProfilePic} alt='profile avatar' />
              </ProfilePicContainer>
              <ArrowBottomIcon />
              {dropdown && (
                <Dropdown ref={wrapperRef}>
                  <Link activeClassName='active' className='link' to='/courses'>
                    Enrollment
                  </Link>
                  <Link className='link' activeClassName='active' to='/profile'>
                    Profile Settings
                  </Link>
                  <Link
                    className='link'
                    activeClassName='active'
                    to='/challenge-list'
                  >
                    All Challanges
                  </Link>
                  {/* {user.role !== 'ROLE_STUDENT' && (
                    <>
                      <Link
                        className='link'
                        activeClassName='active'
                        to='/students'
                      >
                        All Students
                      </Link>
                      <Link
                        className='link'
                        activeClassName='active'
                        to='/grades'
                      >
                        Student Grades
                      </Link>
                      <hr />
                    </>
                  )} */}
                  {user.role === 'ROLE_STUDENT' && (
                    <>
                      {/*
                      <Link
                        className="link"
                        activeClassName="active"
                        to="/dashboard"
                      >
                        My Dashboard
                      </Link>
                      <Link activeClassName="active" className="link" to="#">
                        My Contribution
                      </Link>
                      <Link activeClassName="active" className="link" to="#">
                        Team Contribution
                      </Link>
                      <Link activeClassName="active" className="link" to="#">
                        Product Performance
                      </Link> */}

                      <hr />
                    </>
                  )}

                  {/* <Link
                    className="link"
                    activeClassName="active"
                    to="/challenge-list/?onlyMine=true"
                  >
                    My Challenges
                  </Link> */}
                  <p onClick={() => signOut()}>Log Out</p>
                </Dropdown>
              )}
            </UserInfo>
          ) : (
            <>
              <div className='position-relative open-menu-on-hover'>
                <Button
                  className='m-0'
                  onClick={() => {
                    handleModal('signin')

                  }}
                >
                  {getTranslation('demo', 'DEMO')} <ArrowIcon />
                </Button>
                {/* <div
                  className="position-absolute apply-menu left-0 d-none"
                  id="apply-menu"

                >
                  <ul>
                    {programs.map(program => {
                      return (
                        <li key={program.key}>
                          <a href={`/program/${program.key}`}>
                            {program.data.title}
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                </div> */}
              </div>

              <Button onClick={() => handleModal('login')} secondary>
                {getTranslation('Login', '', true)}
                <ArrowIcon />
              </Button>
            </>
          )}
        </LinksContainer>
      </FixedContainer>
    </Container>
  )
}

export default Header
