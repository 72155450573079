import * as React from 'react'
import styled from 'styled-components'

import Layout from 'layouts/main'
import { SCREEN } from 'styles/screens'
import { FixedContainer } from '../components/styled/containers'
import { getTranslation } from '../data/dictionary'
import RenderHtml from '../components/shared/renderHtml'
import Calendar from 'components/home/calendar'

const Container = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
  }
  iframe {
    width: 100%;
    height: 700px;
    overflow: hidden;
    border: none;
  }
`
const CalendarPage = ({ pageContext }) => {

  return (
    
    <Calendar/>
  )
}

export default CalendarPage
